import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from "@mui/material/Stack";
import { BsLocale, IntlContext, LOCALES } from "../contexts/intl.context";


const SelectLangItem = ({ locale }: { locale: BsLocale }) => <Stack spacing={1} direction="row" alignItems="center">
    <locale.Flag style={{ height: 25, borderRadius: 5 }} />
    {/*<Typography variant="subtitle2"><FormattedMessage id={locale.label} /></Typography>*/}
</Stack>;

const LangSelect = (props: FormControlProps) => {
    const { setLocale } = useContext(IntlContext);
    const { locale } = useIntl();
    
    return <FormControl component={Paper} elevation={0} sx={{
        border: "none",
        bgcolor: "transparent",
        borderColor: "transparent",
        "&.Mui-focused": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
    }} {...props}>
        <Select value={locale} fullWidth onChange={value => setLocale(value.target.value)}>
            {LOCALES.map((locale) => <MenuItem key={locale.value} value={locale.value}>
                <SelectLangItem locale={locale} />
            </MenuItem>)}
        </Select>
    </FormControl>;
};

export default LangSelect;

import React, { useContext, useState } from "react";

import useFormatters from "../../hooks/use-formatters";
import { StepperContext } from "../../contexts/stepper.context";
import { Steps } from "../stepper.component";
import {
  Card,
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  useTheme,
} from "@mui/material";
import BSButton from "../button.component";
import { StorageContext } from "../../contexts/storage.context";
import { ActivationCodeRequest } from "./form.step";
import { BystampApi } from "../../network/bystamp-api";
import {useIntl} from "react-intl";

export interface DistributorLoginRequest {
  email: string;
  prefLang: string;
}

function LoginStep() {
  const { setShowLoginSuccessModal, setShowLoginFailModal } =
    useContext(StepperContext);

  const { setDistributorMail } = useContext(StorageContext);

  const intl = useIntl();
  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  const api = new BystampApi();

  const [mail, setMail] = useState("");
  const [mailError, setMailError] = useState(false);

  const validateEmail = (mail: string) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = regex.test(mail);

    setMail(mail);
    setMailError(!valid);
  };

  const validate = () => {
    if (mail === "" || mailError) return;

    setDistributorMail(mail);

    api
      .signIn({ email: mail, prefLang: intl.locale })
      .then((response) => {
        if (response.status === 200) {
          setShowLoginSuccessModal(true);
        } else {
          setShowLoginFailModal(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setShowLoginFailModal(true);
      });
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      alignItems={"center"}
      marginTop={7}
      width={"100%"}
    >
      <Card
        className="card"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          alignItems={"center"}
          width={"100%"}
        >
          <h1 className="title">{formatMessage("stepper.login.title")}</h1>

          <p className="text-body">{formatMessage("stepper.login.desc")}</p>

          <FormControl sx={{ m: 1, width: "100%" }} required={true}>
            <InputLabel style={{ color: colors.darkGrey2 }}>
              {formatMessage("form.mail")}
            </InputLabel>
            <OutlinedInput
              inputProps={{ inputMode: "email" }}
              label={formatMessage("form.mail")}
              error={mailError}
              onChange={(e) => validateEmail(e.target.value)}
            />
          </FormControl>

          <Stack direction={"row"} spacing={2}>
            <BSButton
              disabled={mail === "" || mailError}
              onClick={() => validate()}
            >
              {formatMessage("stepper.button.login")}
            </BSButton>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
}

export default LoginStep;

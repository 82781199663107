import { createTheme, PaletteMode } from "@mui/material";
import { Localization } from "@mui/material/locale";

declare module "@mui/material/styles" {
    interface Theme {
        colors: {
            black: string;
            white: string;
            blue: string;
            green: string;
            yellow: string;
            red: string;
            lightBlue: string;
            lightGrey: string;
            darkGrey: string;
            darkGrey2: string;
            background: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        colors?: {
            black?: string;
            white?: string;
            blue?: string;
            green?: string;
            yellow?: string;
            red?: string;
            lightBlue?: string;
            lightGrey?: string;
            darkGrey?: string;
            darkGrey2?: string;
            background?: string;
        };
    }
}

export const createMuiTheme = (colorMode: PaletteMode, lang: Localization) => createTheme({
    spacing: 10,
    shape: {
        borderRadius: 10
    },
    colors: {
        black: "#000000",
        white: "#FFFFFF",
        blue: "#2196F3",
        green: "#669900",
        yellow: "#FFC107",
        red: "#FA0A0A",
        lightBlue: "#64B5F6",
        lightGrey: "#ECEFF1",
        darkGrey: "#607D8B",
        darkGrey2: "#546E7A"
    },
    palette: {
        mode: colorMode,
        primary: {
            main: "#2196F3",
            light: "#2196F3"
        },
        secondary: {
            main: "#FA0A0A",
            light: "#FA0A0A",
        },
        success: {
            main: "#669900",
            light: "#669900"
        },
        error: {
            main: "#FA0A0A",
            light: "#FA0A0A"
        },
        background: {
            default: "#ECEFF1"
        }
    },

    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    paddingY: 0,
                    paddingRight: 1,
                    color: "white",
                    backgroundColor: "#2196F3"
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: "32px",
                    fontSize: "15px",
                    textTransform: "none",
                },
                outlined: {
                    borderColor: "black",
                    borderWidth: "2px",
                    color: "black",

                    "&:hover": {
                        borderColor: "black",
                        borderWidth: "2px",
                        color: "black",
                    }
                },
                contained: {
                    "&:hover": {
                        backgroundColor: "#1e8ae0",
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        color: "#2196F3",
                        backgroundColor: "#FFF"
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: "#546E7A"
                }
            }
        },
        MuiFormControl: {
            defaultProps: {
                variant: "outlined"
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderColor: "#CFD8DC",
                    "&.Mui-focused": {
                        borderColor:"#CFD8DC"
                    },
                    "& fieldset": {
                        borderColor: "#CFD8DC"
                    },
                    ":hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2196F3"
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor:"#2196F3"
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor:"#FA0A0A"
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#607D8B",
                    "&.Mui-focused": {
                        color: "#607D8B"
                    }
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: "#97AFB9"
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    textAlign: "left"
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: "#97AFB9"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    color: "#97AFB9",
                    fontSize: "18px"
                },
                body2: {
                    color: "#97AFB9",
                    fontSize: "12px"
                },
                subtitle1: {
                    color: "#97AFB9",
                    fontSize: "14px"
                },
                subtitle2: {
                    fontSize: "16px"
                },
                h2: {
                    color: "#FFF",
                    fontWeight: "bold",
                    fontSize: "36px"
                },
                h3: { //Subtitles
                    fontWeight: "normal",
                    fontSize: "24px"
                },
                h4: { //Titles
                    fontWeight: "bold",
                    fontSize: "24px"
                },
                h5: { //Card subtitle
                    fontWeight: "bold",
                    fontSize: "14px"
                },
                h6: { //Tab titles
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "18px"
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                arrow: {
                    color: "#333333"
                },
                tooltip: {
                    fontSize: "16px",
                    backgroundColor: "#333333",
                    maxWidth: "550px"
                }
            }
        }

    }
}, lang);

import React, { useContext, useEffect } from "react";

import { StepperContext } from "../../contexts/stepper.context";
import { Steps } from "../stepper.component";
import { Stack, useTheme } from "@mui/material";
import useFormatters from "../../hooks/use-formatters";
import BSButton from "../button.component";
import ModelCard from "../modelcard.component";

import { ReactComponent as ModelBlankRect } from "../../assets/models/preview/BlankRect.svg";
import { ReactComponent as ModelRect } from "../../assets/models/preview/Rect.svg";
import { ReactComponent as ModelRectImgL } from "../../assets/models/preview/RectImgL.svg";
import { ReactComponent as ModelRectImgR } from "../../assets/models/preview/RectImgR.svg";
import { ReactComponent as ModelBlankRound } from "../../assets/models/preview/BlankRound.svg";
import { ReactComponent as ModelRound } from "../../assets/models/preview/Round.svg";
import { ReactComponent as ModelRoundOneLine } from "../../assets/models/preview/RoundOneLine.svg";
import { ReactComponent as ModelRoundImage } from "../../assets/models/preview/RoundImage.svg";

export enum Models {
  BLANK_RECT,
  RECT,
  RECT_IMG_L,
  RECT_IMG_R,
  BLANK_ROUND,
  ROUND,
  ROUND_ONE_LINE,
  ROUND_IMAGE,
}

function ModelsStep() {
  const {
    setStep,
    setImprintYmlModel,
    modelList,
    imprintBuilderNextId,
    imprintBuilderObjects,
    imprintBuilderHistoryUndo,
    imprintBuilderHistoryRedo,
  } = useContext(StepperContext);

  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  const selectModel = (model: {
    modelID: number;
    yaml: string;
    resources: { uri: string; src: string }[];
  }) => {
    setImprintYmlModel(model);
    setStep(Steps.BUILDER);
  };

  useEffect(() => {
    // Reset imprint builder states in stepper context when entering this step
    setImprintYmlModel(null);
    imprintBuilderNextId.current = 2;
    imprintBuilderObjects.current = [];
    imprintBuilderHistoryUndo.current = [];
    imprintBuilderHistoryRedo.current = [];
  }, [
    imprintBuilderNextId,
    imprintBuilderObjects,
    imprintBuilderHistoryUndo,
    imprintBuilderHistoryRedo,
  ]);

  return (
    <Stack
      direction="column"
      spacing={{ xs: 1, sm: 2 }}
      useFlexGap
      alignItems={"center"}
    >
      <h1 className="title">{formatMessage("stepper.models.title")}</h1>
      <p className="text-body">{formatMessage("stepper.models.subtitle")}</p>

      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        maxWidth={"1200px"}
        width={"100%"}
      >
        <ModelCard
          title={formatMessage("stepper.models.blank.title")}
          description={""}
          onClick={() => selectModel(modelList.current[Models.BLANK_RECT])}
        >
          <ModelBlankRect />
        </ModelCard>
        <ModelCard
          title={formatMessage("stepper.models.rectangle.title")}
          description={formatMessage("stepper.models.rectangle.desc")}
          onClick={() => selectModel(modelList.current[Models.RECT])}
        >
          <ModelRect />
        </ModelCard>
        <ModelCard
          title={formatMessage("stepper.models.rectangle.leftImage.title")}
          description={formatMessage("stepper.models.rectangle.leftImage.desc")}
          onClick={() => selectModel(modelList.current[Models.RECT_IMG_L])}
        >
          <ModelRectImgL />
        </ModelCard>
        <ModelCard
          title={formatMessage("stepper.models.rectangle.rightImage.title")}
          description={formatMessage(
            "stepper.models.rectangle.rightImage.desc"
          )}
          onClick={() => selectModel(modelList.current[Models.RECT_IMG_R])}
        >
          <ModelRectImgR />
        </ModelCard>

        <ModelCard
          title={formatMessage("stepper.models.blank.title")}
          description={""}
          onClick={() => selectModel(modelList.current[Models.BLANK_ROUND])}
        >
          <ModelBlankRound />
        </ModelCard>
        <ModelCard
          title={formatMessage("stepper.models.round.title")}
          description={formatMessage("stepper.models.round.desc")}
          onClick={() => selectModel(modelList.current[Models.ROUND])}
        >
          <ModelRound />
        </ModelCard>
        <ModelCard
          title={formatMessage("stepper.models.round.oneLine.title")}
          description={formatMessage("stepper.models.round.oneLine.desc")}
          onClick={() => selectModel(modelList.current[Models.ROUND_ONE_LINE])}
        >
          <ModelRoundOneLine />
        </ModelCard>
        <ModelCard
          title={formatMessage("stepper.models.round.image.title")}
          description={formatMessage("stepper.models.round.image.desc")}
          onClick={() => selectModel(modelList.current[Models.ROUND_IMAGE])}
        >
          <ModelRoundImage />
        </ModelCard>
      </Stack>

      <Stack direction={"row"} spacing={2} paddingTop={2} paddingBottom={4}>
        <BSButton type={"reset"} onClick={() => setStep(Steps.MODE)}>
          {formatMessage("stepper.button.home")}
        </BSButton>
      </Stack>
    </Stack>
  );
}

export default ModelsStep;

import { Agent, HttpResponse } from "./agent";
import {ActivationCodeRequest} from "../components/steps/form.step";
import {DistributorLoginRequest} from "../components/steps/login.step";

const baseUrl = process.env.REACT_APP_BASEURL;

export class BystampApi {
    private agent = new Agent();

    private requestMapping = "/core/api/publisher";
    private authRequestMapping = "/core/distributor/";

    signIn(body: DistributorLoginRequest): Promise<HttpResponse<string>> {
        return this.agent.postWithResponseText(`${baseUrl}${this.authRequestMapping}`, body);
    }

    generateActivationCode(
        body: ActivationCodeRequest,
        token?: string
    ): Promise<HttpResponse<any>> {
        return this.agent.post(`${baseUrl}${this.requestMapping}/keymo/activation`, body, token);
    }
}

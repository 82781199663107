import React, { useMemo } from "react";
import {
  Box,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import "./App.css";

import { ReactComponent as LogoBanner } from "./assets/logo_banner.svg";

import StepperProvider from "./contexts/stepper.context";
import { createMuiTheme } from "./Theme";
import * as locales from "@mui/material/locale";
import { useIntl } from "react-intl";
import Stepper from "./components/stepper.component";
import Banner from "./components/banner.component";

function App() {
  const intl = useIntl();

  const lang = useMemo(() => {
    switch (intl.locale) {
      case "fr":
        return locales["frFR"];
      case "en":
        return locales["enUS"];
      case "de":
        return locales["deDE"];
    }
  }, [intl]);

  const muiTheme = useMemo(
    () => createMuiTheme("light", lang ?? locales["frFR"]),
    [lang]
  );

  return (
    <div className="App">
      <MuiThemeProvider theme={muiTheme}>
        <StepperProvider>
          <CssBaseline enableColorScheme />

          <Banner />
          <main className="main">
            <Stepper />
          </main>
        </StepperProvider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;

import React, {
  createContext,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ImprintBuilderHistoryEntry,
  ImprintBuilderObject,
} from "../components/canvas/BSImprintBuilder";
import { Models } from "../components/steps/models.step";
import useIsTouchDevice from "../hooks/use-istouchdevice";

type StepperContextType = {
  step: number;
  showLoginSuccessModal: boolean;
  showLoginFailModal: boolean;
  pngInput: string | null;
  firstname: string;
  lastname: string;
  organization: string;
  mailUser: string;
  imprintPng: string | null;
  imprintYmlModel: {
    modelID: number;
    yaml: string;
    resources: { uri: string; src: string }[];
  } | null;
  imprintBuilderNextId: MutableRefObject<number>;
  imprintBuilderObjects: MutableRefObject<ImprintBuilderObject[]>;
  imprintBuilderHistoryUndo: MutableRefObject<ImprintBuilderHistoryEntry[]>;
  imprintBuilderHistoryRedo: MutableRefObject<ImprintBuilderHistoryEntry[]>;
  fontsList: MutableRefObject<string[]>;
  fontsAvailableVariants: MutableRefObject<string[][]>;
  outOfImprintBounds: boolean;
  modelList: MutableRefObject<
    {
      modelID: number;
      yaml: string;
      resources: { uri: string; src: string }[];
    }[]
  >;
  setStep(step: number): void;
  setShowLoginSuccessModal(show: boolean): void;
  setShowLoginFailModal(show: boolean): void;
  setPngInput(png: string | null): void;
  setFirstname(firstname: string): void;
  setLastname(lastname: string): void;
  setOrganization(organization: string): void;
  setMailUser(mail: string): void;
  setImprintPng(base64: string | null): void;
  setImprintYmlModel(
    model: {
      modelID: number;
      yaml: string;
      resources: { uri: string; src: string }[];
    } | null
  ): void;
  setOutOfImprintBounds(bool: boolean): void;
  isTouchDevice: boolean;
};

export const StepperContext = createContext<StepperContextType>(
  {} as StepperContextType
);

const StepperProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [step, setStep] = useState(0);

  const [showLoginSuccessModal, setShowLoginSuccessModal] = useState(false);
  const [showLoginFailModal, setShowLoginFailModal] = useState(false);

  const [pngInput, setPngInput] = useState<string | null>(null);
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [mailUser, setMailUser] = useState<string>("");
  const [imprintPng, setImprintPng] = useState<string | null>(null);
  const [imprintYmlModel, setImprintYmlModel] = useState<{
    modelID: number;
    yaml: string;
    resources: { uri: string; src: string }[];
  } | null>(null);

  const imprintBuilderNextId = useRef<number>(2);
  const imprintBuilderObjects = useRef<ImprintBuilderObject[]>([]);
  const imprintBuilderHistoryUndo = useRef<ImprintBuilderHistoryEntry[]>([]);
  const imprintBuilderHistoryRedo = useRef<ImprintBuilderHistoryEntry[]>([]);
  const fontsList = useRef<string[]>([
    "Arial",
    "Bahnschrift",
    "Calibri",
    "Consola",
    "Cour",
    "GOST",
    "Humanist",
  ]);
  const fontsAvailableVariants = useRef<string[][]>([
    ["-Regular", "-Bold", "-Italic", "-BoldItalic"],
    ["-Regular"],
    ["-Regular", "-Bold", "-Italic", "-BoldItalic"],
    ["-Regular", "-Bold", "-Italic", "-BoldItalic"],
    ["-Regular", "-Bold", "-Italic", "-BoldItalic"],
    ["-Regular"],
    ["-Regular"],
  ]);
  const [outOfImprintBounds, setOutOfImprintBounds] = useState<boolean>(false);

  const modelList = useRef<
    {
      modelID: number;
      yaml: string;
      resources: { uri: string; src: string }[];
    }[]
  >([
    {
      modelID: Models.BLANK_RECT,
      yaml: "",
      resources: [],
    },
    {
      modelID: Models.RECT,
      yaml: "",
      resources: [],
    },
    {
      modelID: Models.RECT_IMG_L,
      yaml: "",
      resources: [],
    },
    {
      modelID: Models.RECT_IMG_R,
      yaml: "",
      resources: [],
    },
    {
      modelID: Models.BLANK_ROUND,
      yaml: "",
      resources: [],
    },
    {
      modelID: Models.ROUND,
      yaml: "",
      resources: [],
    },
    {
      modelID: Models.ROUND_ONE_LINE,
      yaml: "",
      resources: [],
    },
    {
      modelID: Models.ROUND_IMAGE,
      yaml: "",
      resources: [],
    },
  ]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/yaml/image.png`).then(async (image) => {
      // Convert image to base64
      const blob = await image.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        modelList.current[Models.RECT_IMG_L].resources.push({
          uri: "image.png",
          src: reader.result as string,
        });
        modelList.current[Models.RECT_IMG_R].resources.push({
          uri: "image.png",
          src: reader.result as string,
        });
        modelList.current[Models.ROUND_IMAGE].resources.push({
          uri: "image.png",
          src: reader.result as string,
        });
      };
    });

    fetch(`${process.env.PUBLIC_URL}/yaml/BlankRect.yaml`).then(async (txt) => {
      modelList.current[Models.BLANK_RECT].yaml = await txt.text();
    });
    fetch(`${process.env.PUBLIC_URL}/yaml/Rect.yaml`).then(async (txt) => {
      modelList.current[Models.RECT].yaml = await txt.text();
    });
    fetch(`${process.env.PUBLIC_URL}/yaml/RectImgL.yaml`).then(async (txt) => {
      modelList.current[Models.RECT_IMG_L].yaml = await txt.text();
    });
    fetch(`${process.env.PUBLIC_URL}/yaml/RectImgR.yaml`).then(async (txt) => {
      modelList.current[Models.RECT_IMG_R].yaml = await txt.text();
    });
    fetch(`${process.env.PUBLIC_URL}/yaml/BlankRound.yaml`).then(
      async (txt) => {
        modelList.current[Models.BLANK_ROUND].yaml = await txt.text();
      }
    );
    fetch(`${process.env.PUBLIC_URL}/yaml/Round.yaml`).then(async (txt) => {
      modelList.current[Models.ROUND].yaml = await txt.text();
    });
    fetch(`${process.env.PUBLIC_URL}/yaml/RoundOneLine.yaml`).then(
      async (txt) => {
        modelList.current[Models.ROUND_ONE_LINE].yaml = await txt.text();
      }
    );
    fetch(`${process.env.PUBLIC_URL}/yaml/RoundImg.yaml`).then(async (txt) => {
      modelList.current[Models.ROUND_IMAGE].yaml = await txt.text();
    });
  }, []);

  const isTouchDevice = useIsTouchDevice();

  return (
    <StepperContext.Provider
      value={{
        step,
        showLoginSuccessModal,
        showLoginFailModal,
        pngInput,
        firstname,
        lastname,
        organization,
        mailUser,
        imprintPng,
        imprintYmlModel,
        imprintBuilderNextId,
        imprintBuilderObjects,
        imprintBuilderHistoryUndo,
        imprintBuilderHistoryRedo,
        fontsList,
        fontsAvailableVariants,
        outOfImprintBounds,
        modelList,
        setStep,
        setShowLoginSuccessModal,
        setShowLoginFailModal,
        setPngInput,
        setFirstname,
        setLastname,
        setOrganization,
        setMailUser,
        setImprintPng,
        setImprintYmlModel,
        setOutOfImprintBounds,
        isTouchDevice,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

export default StepperProvider;

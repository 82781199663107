import { BSException } from "./exception.model";

export interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

export class Agent {
    private static async http<T>(
        request: RequestInfo
    ): Promise<HttpResponse<T>> {
        const response: HttpResponse<T> = await fetch(request);
        try {
            // may error if there is no body
            response.parsedBody = await response.json();
        } catch (ex) {
            if (ex instanceof BSException) {
                throw new BSException(ex.message, ex.code);
            } else {
                throw ex;
            }
        }

        if (!response.ok) {
            const err = response.parsedBody as unknown as BSException;
            throw new BSException(err.message, err.code);
        }
        return response;
    }

    private static async httpResponseText(
        request: RequestInfo
    ): Promise<HttpResponse<string>> {
        const response: HttpResponse<string> = await fetch(request);
        try {
            // may error if there is no body
            response.parsedBody = await response.text();
        } catch (ex) {
            if (ex instanceof BSException) {
                throw new BSException(ex.message, ex.code);
            } else {
                throw ex;
            }
        }

        if (!response.ok) {
            const err = response.parsedBody as unknown as BSException;
            throw new BSException(err.message, err.code);
        }
        return response;
    }

    private static async httpVoid(request: RequestInfo) {
        const response: HttpResponse<void> = await fetch(request);
        if (!response.ok) {
            throw new BSException(response.statusText, response.status);
        }
    }

    async get<T>(
        path: string,
        token?: string,
        args: RequestInit = {
            method: "get",
            headers: {}
        }
    ): Promise<HttpResponse<T>> {
        const req = new Request(path, args);
        if (token) {
            req.headers.set("Authorization", token);
        }
        return await Agent.http<T>(req);
    }

    async post<T>(
        path: string,
        body: any,
        token?: string,
        args: RequestInit = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        }
    ): Promise<HttpResponse<T>> {
        const req = new Request(path, args);
        if (token !== undefined) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.http<T>(req);
    }

    async put<T>(
        path: string,
        body: any,
        token?: string,
        args: RequestInit = {
            method: "put",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        }
    ): Promise<HttpResponse<T>> {
        const req = new Request(path, args);
        if (token !== undefined) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.http<T>(req);
    }

    async voidRequest(
        method: "put" | "post",
        path: string,
        body: any,
        token?: string,
        args: RequestInit = {
            method: method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        }
    ): Promise<void> {
        const req = new Request(path, args);
        if (token !== undefined) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.httpVoid(req);
    }

    async postWithResponseText(
        path: string,
        body: any,
        token?: string,
        args: RequestInit = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        }
    ): Promise<HttpResponse<string>> {
        const req = new Request(path, args);
        if (token !== undefined) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.httpResponseText(req);
    }

    async delete(
        path: string,
        token?: string,
        args: RequestInit = {
            method: "delete",
            headers: { "Content-Type": "application/json" }
        }
    ) {
        const req = new Request(path, args);
        if (token !== undefined) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.httpVoid(req);
    }
}

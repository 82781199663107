import React, { useContext, useEffect, useState } from "react";
import "./mode.step.css";
import useFormatters from "../../hooks/use-formatters";
import { StepperContext } from "../../contexts/stepper.context";
import { Steps } from "../stepper.component";
import { Box, Card, Stack, useTheme } from "@mui/material";

import { ReactComponent as ImprintBuilderCardVisual } from "../../assets/ImprintBuilderCardVisual.svg";
import { ReactComponent as PNGvisual } from "../../assets/PNGvisual.svg";

function ModeStep() {
  const {
    setStep,
    setPngInput,
    setFirstname,
    setLastname,
    setImprintPng,
    setImprintYmlModel,
    setOrganization,
    setMailUser,
    modelList,
    isTouchDevice,
  } = useContext(StepperContext);
  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  const [borderColor, setBorderColor] = useState(colors.darkGrey);

  useEffect(() => {
    // Reset all states when entering this step
    setFirstname("");
    setLastname("");
    setOrganization("");
    setMailUser("");
    setImprintPng(null);
    setImprintYmlModel(null);
    setPngInput(null);

    const dragoverHandler = (e: DragEvent) => {
      e.preventDefault();
    };

    const dropHandler = (e: DragEvent) => {
      e.preventDefault();
    };

    const dragleaveHandler = (e: DragEvent) => {
      e.preventDefault();
      setBorderColor(colors.darkGrey);
    };

    // Prevent dropping files anywhere else than in dropzone
    window.addEventListener("dragover", dragoverHandler);
    window.addEventListener("drop", dropHandler);
    window.addEventListener("dragleave", dragleaveHandler);

    return () => {
      window.removeEventListener("dragover", dragoverHandler);
      window.removeEventListener("drop", dropHandler);
      window.removeEventListener("dragleave", dragleaveHandler);
    };
  }, []);

  // handle image drop on import image card
  const handleImageDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setBorderColor(colors.darkGrey);

    const file = e.dataTransfer.files[0];

    if (file && file.type === "image/png") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPngInput(e.target?.result as string);
        setStep(Steps.FORM);
      };
      reader.readAsDataURL(file);
    }
  };

  // handle file input change
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);

    if (file && file.type === "image/png") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPngInput(e.target?.result as string);
        setStep(Steps.FORM);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle dropping over zone to change border color
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setBorderColor(colors.blue);
  };

  return (
    <>
      <h1 className="title">
        {isTouchDevice
          ? formatMessage("stepper.mode.titleTouchDevice")
          : formatMessage("stepper.mode.title")}
      </h1>

      <div className={`${isTouchDevice ? "single-mode" : "mode-layout"}`}>
        {!isTouchDevice && (
          <div className="mode-layout__card">
            <p className="text-body">
              {formatMessage("stepper.mode.model.card.desc")}
            </p>
            <Card
              className="card mode-visual"
              onClick={() => setStep(Steps.MODELS)}
            >
              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                height={"100%"}
              >
                <ImprintBuilderCardVisual className="mode-visual" />
                <h2>{formatMessage("stepper.mode.model.card.label")}</h2>
              </Stack>
            </Card>
          </div>
        )}

        {!isTouchDevice && (
          <div className="title mode-or">{formatMessage("stepper.or")}</div>
        )}

        <div className="mode-layout__card">
          <p className="text-body">
            {formatMessage("stepper.mode.image.card.desc")}
          </p>
          <Card
            className="card mode-visual"
            onClick={() => document.getElementById("fileInput")?.click()}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleImageDrop(e)}
          >
            <Box
              border={"5px dashed " + borderColor}
              borderRadius={1}
              paddingLeft={1}
              paddingRight={1}
              paddingTop={2}
              paddingBottom={1}
              width={"100%"}
              height={"100%"}
            >
              <PNGvisual />
              <h2>{formatMessage("stepper.mode.image.card.label")}</h2>
            </Box>
          </Card>
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          accept="image/png"
          onChange={(e) => handleFileInput(e)}
        />
      </div>
    </>
  );
}

export default ModeStep;

import React, {FC, MouseEvent, ReactNode} from 'react';
import Button from '@mui/material/Button';

interface BSButtonProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    type?: "submit" | "reset";
    disabled?: boolean;
    children: ReactNode;
}

const BSButton: FC<BSButtonProps> = ({ onClick, type = "submit", disabled = false, children }) => {
    return (
        <Button
            variant={type === "submit" ? "contained" : "outlined"}
            type={type}
            disabled={disabled}
            style={{
                width: "240px",
                height: "50px"
            }}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

export default BSButton;

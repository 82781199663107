import React, {useContext, useEffect} from 'react';
import {StepperContext} from "../contexts/stepper.context";

import LoginStep from "./steps/login.step";
import ModeStep from "./steps/mode.step";
import BuilderStep from "./steps/builder.step";
import FormStep from "./steps/form.step";
import ModelsStep from "./steps/models.step";
import ConfirmationStep from "./steps/confirmation.step";
import WelcomeModal from "./modals/welcome.modal";
import {StorageContext} from "../contexts/storage.context";
import LoginSuccessModal from "./modals/login-success.modal";
import LoginFailModal from "./modals/login-fail.modal";

// Enum for the steps
export enum Steps {
    LOGIN,
    MODE,
    MODELS,
    BUILDER,
    FORM,
    CONFIRMATION
}

function Stepper() {
    const {step, setStep} = useContext(StepperContext);

    const {key} = useContext(StorageContext);

    useEffect(() => {
        if (key && key !== "") {
           setStep(Steps.MODE);
        }
    }, [key]);

    const renderStep = () => {
        switch (step) {
            case Steps.LOGIN:
                return <LoginStep/>;
            case Steps.MODE:
                return <ModeStep/>;
            case Steps.MODELS:
                return <ModelsStep/>;
            case Steps.BUILDER:
                return <BuilderStep/>;
            case Steps.FORM:
                return <FormStep/>;
            case Steps.CONFIRMATION:
                return <ConfirmationStep/>;
            default:
                return <></>;
        }
    };

    return (
        <>
            {renderStep()}
            <WelcomeModal/>
            <LoginSuccessModal/>
            <LoginFailModal/>
        </>
    );
}

export default Stepper;

import React, {FC, MouseEvent, ReactNode} from 'react';
import {Card, Stack, useTheme} from "@mui/material";

import './modelcard.component.css';

interface ModelCardProps {
    title: string;
    description: string;
    onClick: (event: MouseEvent<HTMLDivElement>) => void;
    children: ReactNode;
}

const ModelCard: FC<ModelCardProps> = ({ onClick, title, description, children }) => {

    const {colors} = useTheme();

    return (
        <Card
            id={"model-card"}
            style={{
                width: "270px",
                height: "255px",
                borderRadius: "10px"
            }}
            onClick={onClick}
        >
            <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                height={"100%"}
                padding={"12px"}
            >
                <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
                    height={"175px"}
                    borderRadius={"10px"}
                    bgcolor={colors.lightGrey}
                >
                    {children}
                </Stack>
                <p style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    margin: 0,
                    marginTop: 10
                }}>
                    {title}
                </p>
                <p style={{
                    visibility: (description === "" ? "hidden" : "visible"),
                    fontWeight: "bold",
                    fontSize: "14px",
                    margin: 0,
                    color: colors.darkGrey2,
                }}>
                    {description === "" ? "." : description}
                </p>
            </Stack>
        </Card>
    );
};

export default ModelCard;

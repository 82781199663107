import React, { useContext, useState } from "react";

import useFormatters from "../../hooks/use-formatters";
import { StepperContext } from "../../contexts/stepper.context";
import { Steps } from "../stepper.component";
import {
  Alert,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import BSButton from "../button.component";
import { BystampApi } from "../../network/bystamp-api";
import { useIntl } from "react-intl";
import { StorageContext } from "../../contexts/storage.context";

export interface ActivationCodeRequest {
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  prefLang: string;
  file: string;
}

function FormStep() {
  const {
    setStep,
    pngInput,
    imprintPng,
    firstname,
    lastname,
    mailUser,
    organization,
    setFirstname,
    setLastname,
    setOrganization,
    setMailUser,
  } = useContext(StepperContext);
  const { key } = useContext(StorageContext);

  const intl = useIntl();
  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  const api = new BystampApi();

  const [showBuyerForm, setShowBuyerForm] = useState(false);
  const [mailError, setMailError] = useState(false);

  const validateEmail = (mail: string) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = regex.test(mail);

    setMailError(!valid);
    if (valid) setMailUser(mail);
  };

  const validateAndGenerate = () => {
    if (firstname === "" || lastname === "" || mailUser === "") {
      return;
    }

    let png = pngInput ?? imprintPng;
    if (!png) return;

    png = png.replace("data:image/png;base64,", "");

    api
      .generateActivationCode(
        {
          email: mailUser,
          firstName: firstname,
          lastName: lastname,
          organization: organization,
          prefLang: intl.locale,
          file: png,
        } as ActivationCodeRequest,
        "ApiKey " + key
      )
      .then((res) => {
        if (res.ok) {
          setStep(Steps.CONFIRMATION);
        } else console.error(res);
      });
  };

  return (
    <Stack direction="column" spacing={4} alignItems={"center"} width={"100%"}>
      <Stack
        direction="column"
        spacing={1}
        alignItems={"center"}
        width={"100%"}
      >
        <h1 className="title">{formatMessage("stepper.form.title")}</h1>
        <Card className="card">
          <Stack direction={"column"} spacing={2}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl sx={{ m: 1, width: "100%" }} required={true}>
                <InputLabel style={{ color: colors.darkGrey2 }}>
                  {formatMessage("form.firstname")}
                </InputLabel>
                <OutlinedInput
                  required={true}
                  inputProps={{ inputMode: "text" }}
                  label={formatMessage("form.firstname")}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} required={true}>
                <InputLabel style={{ color: colors.darkGrey2 }}>
                  {formatMessage("form.lastname")}
                </InputLabel>
                <OutlinedInput
                  inputProps={{ inputMode: "text" }}
                  label={formatMessage("form.lastname")}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl sx={{ m: 1, width: "100%" }} required={true}>
                <InputLabel style={{ color: colors.darkGrey2 }}>
                  {formatMessage("form.mail")}
                </InputLabel>
                <OutlinedInput
                  inputProps={{ inputMode: "email" }}
                  label={formatMessage("form.mail")}
                  error={mailError}
                  onChange={(e) => validateEmail(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel style={{ color: colors.darkGrey2 }}>
                  {formatMessage("form.organization")}
                </InputLabel>
                <OutlinedInput
                  inputProps={{ inputMode: "text" }}
                  label={formatMessage("form.organization")}
                  onChange={(e) => setOrganization(e.target.value)}
                />
              </FormControl>
            </Stack>
            <FormControlLabel
              style={{ marginTop: "20px" }}
              label={formatMessage("form.buyerNotUser")}
              control={
                <Checkbox
                  style={{paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px"}}
                  onChange={(e) => {
                    setShowBuyerForm(e.target.checked);
                  }}
                />
              }
            />
            <Alert variant="outlined" severity="info" style={{textAlign: "left"}}>
              {formatMessage("form.buyerNotUser.tooltip1")}
              <br /><br />
              {formatMessage("form.buyerNotUser.tooltip2")}
            </Alert>
            <p style={{width: "100%", textAlign: "left", margin: "10px 0px 0px 0px"}}>{formatMessage("form.requiredFields")}</p>
          </Stack>
        </Card>
      </Stack>
      {showBuyerForm && (
        <Stack
          direction="column"
          spacing={1}
          alignItems={"center"}
          width={"100%"}
        >
          <h1 className="title">{formatMessage("stepper.form.buyerTitle")}</h1>
          <Card className="card">
            <Stack direction={"column"} spacing={2}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel style={{ color: colors.darkGrey2 }}>
                    {formatMessage("form.firstname")}
                  </InputLabel>
                  <OutlinedInput
                    required={true}
                    inputProps={{ inputMode: "text" }}
                    label={formatMessage("form.firstname")}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel style={{ color: colors.darkGrey2 }}>
                    {formatMessage("form.lastname")}
                  </InputLabel>
                  <OutlinedInput
                    inputProps={{ inputMode: "text" }}
                    label={formatMessage("form.lastname")}
                  />
                </FormControl>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel style={{ color: colors.darkGrey2 }}>
                    {formatMessage("form.mail")}
                  </InputLabel>
                  <OutlinedInput
                    inputProps={{ inputMode: "email" }}
                    label={formatMessage("form.mail")}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel style={{ color: colors.darkGrey2 }}>
                    {formatMessage("form.organization")}
                  </InputLabel>
                  <OutlinedInput
                    inputProps={{ inputMode: "text" }}
                    label={formatMessage("form.organization")}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      )}

      <Stack
        direction="column"
        spacing={1}
        alignItems={"center"}
        width={"100%"}
      >
        <h1 className="title">{formatMessage("stepper.form.preview")}</h1>
        <Card className="card">
          <img
            src={pngInput ?? imprintPng ?? ""}
            style={{
              maxHeight: "200px",
              objectFit: "contain",
              maxWidth: "100%",
            }}
            alt={formatMessage("stepper.form.preview")}
          />
        </Card>
      </Stack>

      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        paddingTop={2}
        paddingBottom={4}
      >
        <BSButton type={"reset"} onClick={() => setStep(Steps.MODE)}>
          {formatMessage("stepper.button.home")}
        </BSButton>
        {imprintPng && (
          <BSButton type={"reset"} onClick={() => setStep(Steps.BUILDER)}>
            {formatMessage("stepper.button.back")}
          </BSButton>
        )}
        <BSButton
          disabled={
            mailError || firstname === "" || lastname === "" || mailUser === ""
          }
          onClick={() => validateAndGenerate()}
        >
          {formatMessage("stepper.button.generate")}
        </BSButton>
      </Stack>
    </Stack>
  );
}

export default FormStep;

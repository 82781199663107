import { useState } from "react";

let isTouchDeviceChecked = false;
let isTouchDeviceValue = false;

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(isTouchDeviceValue);

  if (!isTouchDeviceChecked) {
    isTouchDeviceValue = "ontouchstart" in window;
    isTouchDeviceChecked = true;
    setIsTouchDevice(isTouchDeviceValue);
  }

  return isTouchDevice;
};

export default useIsTouchDevice;

import { useIntl } from "react-intl";
import { PrimitiveType, FormatXMLElementFn } from "intl-messageformat";

/**
 * Hook for providing a simpler interface for common formats
 */
const useFormatters = () => {
    const intl = useIntl();

    return {
        formatMessage: (
            key: Translation,
            values?:
                | Record<
                      string,
                      PrimitiveType | FormatXMLElementFn<string, string>
                  >
                | undefined
        ) => intl.formatMessage({ id: key }, values)
    };
};

export default useFormatters;

import React, {createContext, useEffect, useState} from "react";

type StorageContextType = {
    locale: string;
    distributorMail: string;
    key: string;
    showWelcomeModal: boolean;
    setLocale(locale: string): void;
    setDistributorMail(mail: string): void;
    setKey(key: string): void;
    setShowWelcomeModal(show: boolean): void;
}

export const StorageContext = createContext<StorageContextType>(
    {} as StorageContextType
);

const StorageProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [locale, setLocale] = useState<string>(localStorage.getItem("locale") ?? (navigator.language || "en"));
    const [distributorMail, setDistributorMail] = useState<string>(localStorage.getItem("distributorMail") ?? "");
    const [key, setKey] = useState<string>(localStorage.getItem("key") ?? "");
    const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>((localStorage.getItem("showWelcomeModal") === null ? true : localStorage.getItem("showWelcomeModal") === "true"));

    useEffect(() => {
        setKey(new URLSearchParams(window.location.search).get("code") ?? key);
    }, [])

    useEffect(() => {
        localStorage.setItem("locale", locale);
        localStorage.setItem("distributorMail", distributorMail);
        localStorage.setItem("key", key);
        localStorage.setItem("showWelcomeModal", showWelcomeModal.toString());
    }, [locale, distributorMail, key, showWelcomeModal])

    return (
        <StorageContext.Provider
            value={{
                locale,
                distributorMail,
                key,
                showWelcomeModal,
                setLocale,
                setDistributorMail,
                setKey,
                setShowWelcomeModal
            }}
        >
            {children}
        </StorageContext.Provider>
    );
};

export default StorageProvider;

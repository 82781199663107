import React, { useContext } from "react";

import useFormatters from "../../hooks/use-formatters";
import { Box, Button, Card, Dialog, Stack, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BSButton from "../button.component";

import { ReactComponent as ErrorSvg } from "../../assets/Error.svg";
import { Steps } from "../stepper.component";
import { StepperContext } from "../../contexts/stepper.context";

const LoginFailModal = () => {
  const { step, showLoginFailModal, setShowLoginFailModal } =
    useContext(StepperContext);
  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  const handleClose = (
    e: any,
    reason: "backdropClick" | "escapeKeyDown" | "button"
  ) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setShowLoginFailModal(false);
  };

  return (
    <Dialog
      open={showLoginFailModal && step === Steps.LOGIN}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        style: {
          maxWidth: "800px",
          width: "100%",
          backgroundColor: "transparent",
        },
      }}
    >
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Box
          width={"100%"}
          height={40}
          bgcolor={colors.blue}
          position={"relative"}
        >
          <Button
            onClick={() => handleClose(null, "button")}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              padding: "0px 0px 0px 10px",
              color: colors.white,
              height: "40px",
              borderRadius: 0,
            }}
          >
            <CloseIcon />
          </Button>
        </Box>

        <Stack
          direction="column"
          spacing={3}
          pt={2}
          pb={2}
          pl={5}
          pr={5}
          textAlign={"center"}
        >
          <h2>{formatMessage("modal.login.fail.title")}</h2>

          <Box display={"flex"} justifyContent={"center"} padding={1}>
            <ErrorSvg />
          </Box>

          <p className="text-body">
            {formatMessage("modal.login.fail.content1")}
          </p>
          <p className="text-body">
            {formatMessage("modal.login.fail.content2")}
          </p>
          <p className="text-body">
            {formatMessage("modal.login.fail.content3")}
          </p>
          <p className="text-body" style={{ fontWeight: "bold" }}>
            hello@bystamp.com
          </p>

          <Stack direction={"row"} spacing={2} justifyContent={"center"}>
            <BSButton onClick={() => handleClose(null, "button")}>
              {formatMessage("stepper.button.tryAgain")}
            </BSButton>
          </Stack>
        </Stack>
      </Card>
    </Dialog>
  );
};

export default LoginFailModal;

import React, { useContext } from "react";

import useFormatters from "../../hooks/use-formatters";
import { Box, Card, Dialog, Stack, useTheme } from "@mui/material";
import BSButton from "../button.component";
import { StorageContext } from "../../contexts/storage.context";

import { ReactComponent as WelcomeModalVisual } from "../../assets/WelcomeModalVisual.svg";
import { Steps } from "../stepper.component";
import { StepperContext } from "../../contexts/stepper.context";

const WelcomeModal = () => {
  const { step } = useContext(StepperContext);
  const { showWelcomeModal, setShowWelcomeModal } = useContext(StorageContext);
  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  const handleClose = (
    e: any,
    reason: "backdropClick" | "escapeKeyDown" | "button"
  ) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setShowWelcomeModal(false);
  };

  return (
    <Dialog
      open={showWelcomeModal && step !== Steps.LOGIN}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        style: {
          maxWidth: "800px",
          width: "100%",
          backgroundColor: "transparent",
          overflow: "auto",
          margin: "16px",
        },
      }}
    >
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Box width={"100%"} minHeight={40} bgcolor={colors.blue}></Box>
        <Stack direction="column" spacing={2} pt={2} pb={2} pl={5} pr={5}>
          <h2 style={{ textAlign: "center" }}>
            {formatMessage("modal.welcome.title")}
          </h2>

          <Box display={"flex"} justifyContent={"center"} padding={1}>
            <WelcomeModalVisual
              style={{
                width: "100%",
                maxWidth: "287px",
              }}
            />
          </Box>

          <p className="text-body" style={{ textAlign: "justify" }}>
            {formatMessage("modal.welcome.content1")}
          </p>
          <p className="text-body" style={{ textAlign: "justify" }}>
            {formatMessage("modal.welcome.content2")}
          </p>
          <p className="text-body" style={{ textAlign: "justify" }}>
            {formatMessage("modal.welcome.content3")}
          </p>

          <Stack direction={"row"} spacing={2} justifyContent={"center"}>
            <BSButton onClick={() => handleClose(null, "button")}>
              {formatMessage("stepper.button.start")}
            </BSButton>
          </Stack>
        </Stack>
      </Card>
    </Dialog>
  );
};

export default WelcomeModal;
